/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
@import url('~react-toastify/dist/ReactToastify.css');

.NotificationAlert-container {
  min-width: 240px;

  max-width: 30%;
}

.NotificationAlert-container > .Notification {
  min-height: 60px;
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
  font-family: inherit;
  overflow: initial;
  cursor: default;
  margin: 0px;
}
.NotificationAlert-container > .Notification > .Notification-body {
  width: 100%;
}

.NotificationAlert-container > .Notification.Notification-dismissed {
  display: none !important;
}
