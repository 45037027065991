/* Polar chart css */
.polartooltip {
  position: absolute;
  padding: 5px 12px 3px 7px;
  margin-left: 5px;
  background: #666;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  display: none;
  width: max-content;
  max-width: 180px;
}

image.resetzoom,
image.resizein,
image.copy{
  padding-right: 30px;
  opacity: 0.5;
}
image.resetzoom:hover,
image.resizein:hover,
image.copy:hover {
  opacity: 1;
  cursor: pointer;
}

#tooltip {
  position: absolute;
  padding: 5px 12px 3px 7px;
  margin-left: 5px;
  background: #666;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  display: none;
}
