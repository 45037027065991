/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
.SearchPanel__Root {
  justify-content: center;
  position: relative;
  display: none;
}

.SearchPanel__Root_is-open {
  justify-content: center;
  position: relative;
  display: flex;
  min-height: 300px;
}

.SearchForm__Root {
  padding: 15px;
  height: 100%;
  flex: 1 1 0%;
}
