/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
body {
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
  font-size: 12px;
}
html,
body,
#root {
  height: 100%;
}
